.notFoundBox {
  display: flex;
  width: 100%;
  padding-top: 100px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.notFoundHeader {
  display: flex;
  align-items: center;
  margin: 20px;
}

.notFoundIcon {
  color: var(--error-color);
  font-size: 30px;
  margin-right: 15px;
}

.notFoundUrl {
  color: var(--npc-blue);
}

.notFoundBtn {
  width: 120px;
  padding: 10px 20px;
  background-color: var(--primary-color);
  font-weight: 600;
  color: var(--ground_color);
  border-radius: 5px;
}

.notFoundBtn:hover {
  background-color: var(--primary-hover);
}
