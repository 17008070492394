.modal {
  display: none;
  position: fixed;
  z-index: 999;
  padding-top: 100px;
  padding-bottom: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.container {
  position: relative;
  background-color: #fefefe;
  color: var(--dark-color);
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: fit-content;
  max-width: 100%;
  height: max-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.body {
  padding-top: 20px;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 50px;
  max-height: 75vh;
  overflow-y: auto;
}

.bodyWithoutHeader {
  padding-top: 0;
}

.header {
  width: 100%;
  padding: 10px 20px;
  min-height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--primary-color);
}

.headerWithoutHeader {
  border-bottom: none;
  min-height: 40px;
  padding: 0;
}

.closeIcon {
  position: absolute;
  top: 5px;
  right: 10px;
  color: var(--disable-color);
  width: 35px;
  min-width: 35px;
  height: auto;
  cursor: pointer;
}

.closeIcon:hover {
  color: var(--primary-color);
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 85%;
  }
}
