/* Global Styles */
:root {
  --ground_color: white;
  --primary-color: #2f3440;
  --secondary-color: #dd7e43;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --error-color: #ff3333;
  --success-color: #28a745;
  --disable-color: #a7a19d;
  --back-color: #f0f0f0;
  --axis-background: #f6f6f6;
  --info-color: #f1f1f1;
  --chart-green-color: #91c559;
  --chart-orange-color: #ffc756;
  --chart-blue-color: #577ecb;
  --table-border-color: #c2c2c2;
  --secondary-hover: #e09262;
  --primary-hover: #464c5a;
  --search-hover: rgba(224, 146, 98, 0.3);
  --npc-green: rgba(3, 151, 37, 0.5);
  --npc-cell-green: rgba(1, 175, 42, 0.2);
  --npc-cell-green2: rgba(1, 175, 42, 0.4);
  --npc-blue: rgba(0, 87, 248, 0.5);
  --npc-violet: #bc9ed7;
  --npc-cell-violet: #d9c0ee;
  --npc-cell-blue: rgba(0, 87, 248, 0.2);
  --npc-cell-orange: #f5b48c;
  --eula-header: #d9d9d9;
  --eula-body: #f5f5f5;
  --eula-text: #ebebeb;
  --eula-body-hover: #f3e9e3;
  --shadows-color: #c2c2c2;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto';
  font-size: 1rem;
  background-color: var(--ground_color);
  color: var(--primary-color);
  overflow-y: auto;
}

/* Scroll bar */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* reCaptcha */
.grecaptcha-badge {
  visibility: hidden;
}

.footer-recaptcha {
  display: flex;
  align-items: center;
  width: 300px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--axis-background);
  font-size: 12px;
  border-radius: 5px;
  box-shadow: 2px 2px 4px var(--disable-color);
  height: 60px;
  transition: transform 0.6s ease-in-out;
}

.footer-recaptcha a {
  color: var(--secondary-color);
}

.footer-recaptcha img {
  height: 50px;
  width: auto;
  margin-right: 10px;
}

/* .footer-recaptcha-text {
  width: 250px;
} */

@media (min-width: 1024px) {
  .footer-recaptcha {
    position: fixed;
    bottom: 0;
    right: 0;
    transform: translateX(78%);
  }

  .footer-recaptcha:hover {
    transform: translateX(0);
  }
}

/* Links */
a {
  text-decoration: none;
}

.file-link {
  width: auto;
  height: auto;
}

.light-link {
  color: var(--light-color) !important;
}

.black-link {
  color: var(--primary-color);
}

.active-link {
  cursor: pointer;
  color: var(--secondary-color);
  border-bottom: 1px solid var(--secondary-color);
}

.link-underline {
  cursor: pointer;
  color: var(--secondary-color);
  text-decoration: underline;
}

.active-link-secondary {
  cursor: pointer;
  color: var(--secondary-color);
}

.active-link-secondary:hover {
  color: rgba(243, 73, 23, 0.8);
}

.active-black-link {
  color: var(--primary-color) !important;
}

/* .nav-link {
  height: 45px !important;
  padding: 5px 14px !important;
} */

.active-link:hover {
  color: rgba(243, 73, 23, 0.8);
  border-bottom: 1px solid rgba(243, 73, 23, 0.8);
}

.active-child-link {
  color: var(--dark-color);
}
.active-child-link:hover {
  color: var(--disable-color);
}

.primary-link {
  color: var(--primary-color);
  text-decoration: none;
}

.disabled-link {
  pointer-events: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

h1 {
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-color);
}

h2 {
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-color);
}

h3 {
  font-size: 15px;
  color: var(--primary-color);
}

h4 {
  font-size: 15px;
  color: var(--primary-color);
}

/* Images */
.nav-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.small-image {
  width: 50px;
  margin-right: 10px;
  margin-left: 50px;
}

.drawer-avatar {
  display: flex;
  align-items: center;
}
/* Backgrounds */
.disable-background {
  background-color: var(--axis-background);
  color: var(--primary-color);
}

.primary-background {
  background-color: var(--primary-color);
  color: var(--ground_color);
}

.secondary-background {
  background-color: var(--secondary-color);
  color: var(--ground_color);
}

/* Spinners block */

.spinner-container-page {
  display: grid;
  height: 60vh;
  justify-content: center;
  align-content: center;
}

.spinner-container-page-scale {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100%;
}

/* Buttons */
.login-button-container {
  margin: 15px auto;
}

.edit-button {
  background-color: #ff3333 !important;
}

.edit-button:hover {
  background-color: #f97474 !important;
}

/* .user-button {
  background-color: var(--primary-color) !important;
  padding: 5px 25px !important;
  border-radius: 15px !important;
  float: right;
  margin-bottom: 10px !important;
  margin-right: 30px !important;
} */
.user-button {
  float: right;
  margin-bottom: 10px;
  margin-right: 30px;
}

.delete-button {
  background-color: var(--error-color) !important;
  padding: 5px 25px !important;
  border-radius: 15px !important;
}

.main-button {
  background-color: var(--primary-color) !important;
  padding: 5px 25px !important;
  border-radius: 15px !important;
}

.delete-button:hover {
  background-color: rgb(255, 100, 100) !important;
}

.main-button:hover {
  background-color: #fb9746 !important;
}

.btn-modal {
  margin: 20px auto 0 !important;
  background-color: #ef0045 !important;
}

.btn-modal:hover {
  background-color: #b60050 !important;
}

.item-view {
  margin: 0px !important;
  padding: 3px !important;
}

input[type='file'] {
  opacity: 0;
  z-index: -1;
  position: absolute;
}

.dashboard-button {
  background-color: var(--secondary-color);
  color: var(--ground_color);
  border-radius: 5px;
  font-weight: 600;
  padding: 10px 20px;
  box-shadow: 2px 2px 5px var(--disable-color);
}

.dashboard-button:hover {
  background-color: var(--secondary-hover);
  box-shadow: 3px 3px 5px var(--disable-color);
}

/* Icons */
.nav-icon-mail {
  width: 40px;
  margin: 10px;
}

.nav-mi-icon {
  font-size: 34px !important;
  cursor: pointer;
  margin: 10px;
}

.nav-chat-box {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.nav-chat-icon {
  font-size: 34px !important;
  cursor: pointer;
  margin-bottom: 0;
}

.nav-icon {
  vertical-align: middle;
  height: 40px;
}

.nav-user-icon {
  width: 40px !important;
  margin-right: 10px;
}

.nav-icon-close {
  vertical-align: middle;
  margin: 10px;
  height: 50px;
  color: var(--ground_color);
}

.nav-icon path {
  fill: var(--primary-color);
}

.active {
  cursor: pointer;
}

.nav-active {
  vertical-align: middle;
  cursor: pointer;
  margin: 10px;
}

.contacts-icon:hover {
  padding: 2px;
  border: 1px solid var(--light-color);
  border-radius: 2px;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.user-icon {
  margin-top: 15px;
  margin-bottom: 15px;
}

.print-icon {
  cursor: pointer;
  height: 30px !important;
  width: 30px !important;
  color: var(--primary-color);
}

.info-icon {
  position: relative;
  cursor: pointer;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  padding: 1px 6px;
  font-weight: 700;
  font-size: 12px;
  border-radius: 50%;
  background-color: var(--info-color);
  color: var(--secondary-color);
}

.table-icon {
  margin-left: 0 !important;
  margin-right: 0 !important;
  background-color: var(--ground_color) !important;
}

.new-icon {
  padding: 1px 6px;
  font-size: 11px;
  border-radius: 5px;
  background-color: var(--error-color);
  color: var(--ground_color);
  margin-left: 20px;
}

.new-count-icon {
  padding: 1px 5px;
  font-size: 11px;
  border-radius: 50%;
  background-color: var(--error-color);
  color: var(--ground_color);
}

.online-icon-box {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.online-icon {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--success-color);
}

.online-icon-text {
  font-size: 12px;
  margin-left: 5px;
}

.print-icon:hover {
  color: var(--secondary-color);
}

.close-icon:after {
  display: inline-block;
  content: '\00d7';
}

.clear-selection-icon {
  color: #676768;
  margin-left: 10px;
  cursor: pointer;
  font-size: 25px;
}

.clear-selection-icon:hover {
  color: #858585;
}

.upload-icon {
  font-size: 23px;
}

.upload-icon:hover {
  color: var(--disable-color);
}

@media (min-width: 768px) {
  .nav-icon {
    margin: 10px;
  }
}

/* Pagination */
.admin-pagination > li > button {
  color: var(--primary-color) !important;
}

.admin-pagination > li > div {
  color: var(--primary-color) !important;
}

.admin-pagination > li > .Mui-selected {
  background-color: rgba(245, 127, 32, 0.6);
}

.lqs-pagination {
  justify-content: center;
}

/* Select */
.brand-select {
  width: 200px;
  border-radius: 2px;
  padding-left: 5px !important;
  background-color: var(--ground_color);
}

.brand-select::before {
  border: 0 !important;
}

.brand-select::after {
  border: 0 !important;
}

.brand-select div:focus {
  background-color: var(--ground_color) !important;
}

.brand-select-label {
  display: none !important;
}

.npc-select {
  width: 170px;
  border-radius: 2px;
  padding-left: 5px !important;
  background-color: var(--ground_color);
  border: 1px solid var(--disable-color);
}

.npc-select::before {
  border: 0 !important;
}

.npc-select::after {
  border: 0 !important;
}

.npc-select div:focus {
  background-color: var(--ground_color) !important;
}

.npc-select-label {
  display: none !important;
}

.npc-select-list {
  max-height: 300px !important;
}

.modal-select {
  padding: 12px !important;
}

.modal-select-label {
  background-color: white;
  padding: 2px !important;
}

.user-info-select {
  color: var(--primary-color) !important;
  border-bottom: 2px solid var(--primary-color) !important;
}

/* Inputs */
.wide {
  width: 250px !important;
}

.extended {
  width: 180px !important;
}

.short {
  width: 80px !important;
}

.long {
  width: 280px !important;
}

.user-info-input {
  margin-right: 15px !important;
}

.text-input {
  padding: 5px;
}

.modal-unput {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

/* Relative Table */
.table-row-hover:hover {
  background-color: var(--ground_color);
}

/* Text styles*/
.dashboard-text {
  line-height: 1.5;
  padding-top: 15px;
  padding-bottom: 15px;
}

.contacts-role {
  font-size: 15px;
  color: var(--disable-color);
}

.sellers-last-update {
  justify-self: right;
  font-size: 15px;
  color: var(--disable-color);
}

.lqs-days {
  margin: 5px;
  cursor: pointer;
}

.lqs-days-text {
  margin: 5px;
}

.selected {
  color: var(--secondary-color);
  font-weight: 600;
  border-bottom: 2px solid var(--secondary-color);
}

.secondary-text {
  color: var(--secondary-color);
  margin-left: 10px;
  font-weight: 600;
}

.lqs-report-text {
  margin-top: 10px;
}

.info-text {
  margin-top: 10px;
}

.currency-chart-text {
  display: none;
  font-size: 11px;
  fill: var(--primary-color);
}

.progress-text {
  font-size: 14px;
  font-weight: 600;
  fill: var(--ground_color);
}

.chart-label-text {
  font-size: 14px;
  font-weight: 600;
}

.chart-text-axis {
  font-size: 12px;
  fill: var(--primary-color);
}

.chart-text-axis-sqs {
  display: flex;
  align-items: center;
  font-size: 12px;
  fill: var(--primary-color);
}

.chart-text-label {
  display: none;
  font-size: 12px;
  fill: var(--primary-color);
}

.chart-text-map-label {
  font-size: 12px;
  fill: var(--primary-color);
}

.chart-text-map-in-label {
  font-size: 12px;
  fill: var(--ground_color);
}

.progress-label {
  font-size: 24px;
  font-weight: 600;
  fill: var(--primary-color);
}

.text-to-center {
  text-align: center;
}

.text-underlined {
  text-decoration: underline;
}

.block-to-center {
  margin-left: auto;
  margin-right: auto;
}

.bold-text {
  font-weight: 600;
}

.disabled-text {
  color: var(--disable-color);
}

.secondary-text-color {
  color: var(--secondary-color);
}

.text-green {
  color: var(--success-color);
}

.header-text {
  text-align: center;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .currency-chart-text {
    display: block;
  }

  .chart-text-label {
    display: block;
  }
}

/* Date Picker */
.lqs-date-picker > .MuiInputBase-root {
  color: var(--primary-color);
}

.lqs-date-picker > .MuiInput-underline:after {
  border-bottom: 2px solid var(--primary-color);
}

.lqs-date-picker > .MuiInput-underline:before {
  border-bottom: 1px solid var(--primary-color);
}

.datepicker-icon {
  color: var(--primary-color);
}

/* My Select */
.select-container {
  width: 200px;
}

.select-container-vendor {
  width: 200px;
}

.select-icon {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 10px;
  height: 15px;
  pointer-events: none;
  padding-left: 5px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.brands-list {
  max-height: 400px !important;
}

/* Table Styles */
.standard-table-header {
  display: grid;
  font-size: 13px;
  font-weight: 600;
  background-color: var(--axis-background);
  margin-right: 12px;
  border-top: 1px solid var(--primary-color);
  border-left: 1px solid var(--primary-color);
  width: fit-content;
}

.standard-table-row {
  display: grid;
  border-left: 1px solid var(--disable-color);
}

.standard-table-body {
  max-height: 400px;
  overflow-y: auto;
  font-size: 12px;
}

/* Table Filters */
.table-filter-header {
  cursor: pointer;
}

.table-filter-header:hover + .filter-arrow-hover {
  opacity: 0.5;
}

.filter-arrow-hover {
  width: 15px !important;
  min-width: 15px !important;
  height: 15px !important;
  margin-left: 2px;
  opacity: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.filter-arrow {
  width: 15px !important;
  min-width: 15px !important;
  height: 15px !important;
  margin-right: 2px;
  margin-top: auto;
  margin-bottom: auto;
  opacity: 0;
}

.active-arrow-asc {
  opacity: 1;
  transform: rotate(0deg);
}

.active-arrow-dsc {
  opacity: 1;
  transform: rotate(180deg);
}

.active-arrow-asc-green {
  opacity: 1;
  transform: rotate(0deg);
  color: var(--success-color);
}

.active-arrow-dsc-red {
  opacity: 1;
  transform: rotate(180deg);
  color: var(--error-color);
}

.arrow-disabled {
  opacity: 0;
}

/* Disabled styles */
.filter-disabled {
  background-color: var(--ground_color);
  border: 1px solid var(--disable-color);
  color: var(--disable-color) !important;
}

/* Color styles */
.secondary-background {
  background-color: var(--secondary-color);
  color: var(--ground_color);
  border: 1px solid var(--ground_color);
}

.primary-background {
  background-color: var(--primary-color);
  color: var(--ground_color);
  border: 1px solid var(--ground_color);
}

.blue-background {
  background-color: var(--chart-blue-color);
  color: var(--ground_color);
  border: 1px solid var(--ground_color);
}

.gray-background {
  background-color: #616b84;
  color: var(--ground_color);
  border: 1px solid var(--ground_color);
}

.yellow-background {
  background-color: var(--chart-orange-color);
  color: var(--ground_color);
  border: 1px solid var(--ground_color);
}

.green-background {
  background-color: var(--chart-green-color);
  color: var(--ground_color);
  border: 1px solid var(--ground_color);
}

.red-background {
  background-color: var(--error-color);
  color: var(--ground_color);
  border: 1px solid var(--ground_color);
}

.transparent-orange-background {
  background-color: rgba(221, 126, 67, 0.5);
  border: 1px solid var(--ground_color);
}

.transparent-lightgreen-background {
  background-color: rgba(93, 197, 100, 0.5);
  border: 1px solid var(--ground_color);
}

/* Tabs */
.disabled-tab {
  display: none !important;
}

.active-tab {
  display: block;
}

.active-header {
  border: 2px solid var(--secondary-color) !important;
  color: var(--secondary-color) !important;
  background-color: var(--ground_color) !important;
}

/* Checkbox */
.filters-checkbox-black + label svg {
  margin-bottom: 3px;
}
.filters-checkbox-black + label svg path {
  stroke: var(--primary-color) !important;
}

.filters-checkbox-green + label svg {
  margin-bottom: 3px;
}
.filters-checkbox-green + label svg path {
  stroke: var(--success-color) !important;
}

/* Toggle switch */
.switch-box {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 25px;
  margin-left: 15px;
  margin-right: 15px;
}

.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-slider:before {
  position: absolute;
  content: '';
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: var(--ground_color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-input-dashboard {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-input-dashboard:checked + .switch-slider {
  background-color: var(--success-color);
}

/* .switch-input:checked + .switch-slider {
  background-color: #2196f3;
}

.switch-input:focus + .switch-slider {
  box-shadow: 0 0 1px #2196f3;
} */

.switch-input:checked + .switch-slider:before,
.switch-input-dashboard:checked + .switch-slider:before {
  -webkit-transform: translateX(35px);
  -ms-transform: translateX(35px);
  transform: translateX(35px);
}

.slider-round {
  border-radius: 15px;
}

.slider-round:before {
  border-radius: 50%;
}

/* Search */
.search-bar-container {
  position: relative;
  margin: 10px;
}

.search-bar-box {
  display: flex;
  align-items: center;
  border: 1px solid var(--primary-color);
  padding: 7px 10px;
}

.search-bar-input {
  width: 250px;
  border: none;
  font-size: 18px;
  margin-right: 10px;
}

.search-bar-input:focus {
  outline: none;
}

/* Page 404 */

.not-selectable {
  user-select: none;
}

.link-icon {
  width: 22px;
  height: auto;
}
