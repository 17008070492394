.textEditorContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 500px;
  border: 1px solid var(--disable-color);
  border-radius: 5px;
}

.textEditorBox {
  height: 250px;
  overflow-y: auto;
  padding: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
}

.textEditorBox ul {
  list-style: initial;
}

.textEditorBox:focus {
  outline: none;
}

.textEditorBox[placeholder]:empty:before {
  content: attr(placeholder);
  color: #555;
}

.textEditorError {
  border: 1px solid var(--error-color);
}

.textEditorToolbarBox {
  position: relative;
}

.textEditorToolbar {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 3px;
  padding: 5px;
  bottom: 40px;
  transition: opacity 0.1s ease-in-out;
  background-color: var(--ground_color);
  box-shadow: 1px 2px 3px 1px rgba(33, 33, 33, 0.2);
  width: max-content;
}

.textEditorToolbarActive {
  visibility: visible;
  opacity: 1;
}

.textEditorBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  font-weight: 600;
  font-size: 15px;
  background-color: var(--axis-background);
  box-shadow: 1px 1px 2px var(--disable-color);
  border-radius: 3px;
  border: none;
  margin: 5px;
  cursor: pointer;
}

.textEditorBtn:hover {
  background-color: rgb(240, 240, 240);
  box-shadow: 2px 2px 4px var(--disable-color);
}

.textEditorBtnActive {
  color: var(--npc-blue);
}

.textEditorDevider {
  margin: 0 5px;
  height: 20px;
  width: 1px;
  background-color: var(--disable-color);
}

.textEditorBox li {
  margin-left: 20px;
}

.textEditorItalic {
  font-style: italic;
}

.textEditorUnderlined {
  text-decoration: underline;
}

.textEditorBold {
  font-weight: 600;
}

.textEditorMenu {
  margin: 7px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.textEditorMenuBtn {
  border: none;
  padding: 3px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  background-color: transparent;
  color: rgb(105, 101, 101);
}

.textEditorMenuBtnActive {
  background-color: var(--axis-background);
}

.textEditorMenuBtnSelected {
  color: var(--npc-blue);
}

.textEditorMenuBtn:hover {
  background-color: var(--axis-background);
}

.textEditorPlaceholder {
  color: var(--disable-color);
}

.attachedBox {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 7px;
  padding: 5px;
}

.attachedBtn {
  margin-top: 3px;
}

.error {
  width: 230px;
  color: var(--error-color);
  font-size: 12px;
  height: 15px;
  text-align: left;
}

.label {
  position: absolute;
  top: -7px;
  left: 10px;
  font-size: 12px;
  background-color: #fefefe;
  padding: 0 5px;
  color: rgb(119, 119, 119);
}

.previewContainer {
  position: relative;
}
.previewIcon {
  margin-left: auto;
  font-size: 24px;
  cursor: pointer;
}
.previewIcon:hover {
  color: var(--disable-color);
}

.previewIconActive {
  color: var(--npc-blue) !important;
}
.previewBox {
  position: absolute;
  bottom: 43px;
  right: 0;
  max-width: 300px;
  max-height: 300px;
  background-color: var(--axis-background);
  overflow-y: auto;
  overflow-x: auto;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 2px var(--disable-color);
  z-index: 10000;
  border: 1px solid var(--disable-color);
}

.previewBoxArrow {
  position: absolute;
  bottom: 26px;
  right: 7px;
  font-size: 25px;
  color: var(--disable-color);
  z-index: 10001;
}

.previewContent {
  transform: scale(0.5);
  transform-origin: 0 0;
  max-height: 250px;
}

@media (min-width: 768px) {
  .previewBox {
    max-width: 500px;
  }
}
