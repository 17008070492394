.modalContainer {
  background-color: #fefefe;
  color: var(--dark-color);
  margin: auto;
  padding: 15px;
  border-radius: 5px;
  max-width: 500px;
  min-height: 250px;
  height: fit-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.modalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px !important;
}

.modalHeaderContainer {
  display: none !important;
}

.modalConfirmIcon {
  font-size: 55px;
  color: var(--error-color);
}

.modalConfirmMessage {
  font-size: 18px;
  margin: 30px;
  text-align: center;
}

.modalConfirmButtons {
  display: flex;
  align-items: center;
}

.modalBtn {
  margin: 10px;
  padding: 10px 20px;
  box-shadow: 2px 2px 5px var(--disable-color);
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  background-color: var(--primary-color);
  font-size: 18px;
  font-weight: 600;
  color: var(--ground_color);
  justify-self: center;
}

.modalBtn:hover {
  box-shadow: 3px 3px 7px var(--disable-color);
}

.modalBtnCancel {
  color: var(--primary-color);
  background-color: var(--ground_color);
  border: 1px solid var(--disable-color);
}

.modalBtnCancel:hover {
  color: var(--disable-color);
  border: 1px solid var(--primary-color);
}

.modalBtnUpdate:hover {
  background-color: var(--secondary-color);
}

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
