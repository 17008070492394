.button {
  display: flex;
  padding: 10px 20px;
  column-gap: 5px;
  background-color: var(--primary-color);
  font-weight: 600;
  color: var(--ground_color);
  border-radius: 5px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  align-items: center;
}

.button:hover {
  background-color: var(--primary-hover);
}

.button:disabled {
  background-color: var(--disable-color);
  cursor: not-allowed;
}

.userButton {
  background-color: var(--primary-color) !important;
  padding: 10px 25px !important;
  border-radius: 15px !important;
  font-weight: 560 !important;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  font-size: 14px !important;
}

.userButton:hover:not(.userButton:disabled) {
  background-color: #fb9746 !important;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.userButton:disabled {
  background-color: var(--disable-color) !important;
  cursor: not-allowed;
}

.resetButton {
  background-color: var(--secondary-color) !important;
  padding: 10px 25px !important;
  border-radius: 15px !important;
  font-weight: 560 !important;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  font-size: 14px !important;
}

.resetButton:hover:not(.userButton:disabled) {
  background-color: #fb9746 !important;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.resetButton:disabled {
  background-color: var(--disable-color) !important;
  cursor: not-allowed;
}

.lqsButton {
  padding: 5px 15px;
  font-size: 14px !important;
  background-color: rgb(255, 246, 231);
  border: 2px solid var(--secondary-color);
  color: var(--secondary-color);
  border-radius: 17px !important;
  box-shadow: 2px 2px 5px var(--table-border-color);
}

.lqsButton:hover {
  background-color: rgb(255, 241, 216);
}

.deleteButton {
  background-color: var(--error-color) !important;
}

.deleteButton:hover {
  background-color: rgb(255, 100, 100) !important;
}

.activateButton {
  background-color: var(--success-color) !important;
}

.activateButton:hover {
  background-color: #46c463 !important;
}

.buttonLoader {
  width: 15px !important;
  height: 15px !important;
  margin-right: 5px;
}

.secondaryButton {
  background-color: #ee7900;
  border-radius: 15px;
  border: none;
  text-align: center;
  padding: 7px 30px;
  cursor: pointer;
  box-shadow: 1px 1px 2px var(--disable-color);
  color: var(--ground_color);
  font-weight: 600;
  font-size: 18px;
}

.secondaryButton:hover {
  background-color: #e07706;
  box-shadow: 2px 2px 4px var(--disable-color);
}

.secondaryButton:disabled {
  background-color: var(--disable-color);
  cursor: not-allowed;
  color: var(--primary-color);
}
