/* Advertising */

.advertising-body {
  display: flex;
  flex-direction: column;
}

.adv-options-modal {
  max-width: 600px;
  overflow-y: auto;
}

.adv-options-modal-header {
  border-bottom: none;
}

.advertising-options-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.advertising-options-item {
  width: 480px;
  margin: 10px;
  box-shadow: 0 0 5px 0 var(--primary-color);
}

.advertising-header-text {
  text-align: center;
  margin-bottom: 10px;
}

.advertising-nodata-text {
  margin-top: 20px;
}

.advertising-nodata-contact {
  padding: 5px 7px;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  background-color: var(--axis-background);
  margin-left: 5px;
  margin-right: 5px;
}

.advertising-nodata-contact:hover {
  box-shadow: 1px 1px 2px var(--disable-color);
}

.advertising-nodata-link {
  color: var(--primary-color);
}

.advertising-param-list li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  padding: 5px 10px;
}

.advertising-param-header {
  width: 130px;
  font-size: 16px;
  font-weight: 600;
}

.advertising-param-value {
  margin-left: 10px;
}

.advertising-table-header {
  display: grid;
  grid-template-columns:
    90px 100px 110px 100px 200px 150px repeat(3, 110px) repeat(3, 145px)
    110px;
  font-size: 13px;
  font-weight: 600;
  background-color: var(--axis-background);
  border-top: 1px solid var(--primary-color);
  border-left: 1px solid var(--primary-color);
  margin-right: 13px;
}

.advertising-table-row {
  display: grid;
  grid-template-columns:
    90px 100px 110px 100px 200px 150px repeat(3, 110px) repeat(3, 145px)
    110px;
}

.advertising-table-row:hover {
  background-color: var(--ground_color);
}

.advertising-table-body {
  max-height: 400px;
  overflow-y: auto;
  font-size: 12px;
  border-left: 1px solid var(--disable-color);
}

.advertising-keywords-table-header {
  display: grid;
  grid-template-columns: 200px 150px 150px repeat(4, 110px);
  font-size: 13px;
  font-weight: 600;
  background-color: var(--axis-background);
  border-top: 1px solid var(--primary-color);
  border-left: 1px solid var(--primary-color);
  margin-right: 13px;
}

.advertising-keywords-table-row {
  display: grid;
  grid-template-columns: 200px 150px 150px repeat(4, 110px);
}

.advertising-keywords-table-row:hover {
  background-color: var(--ground_color);
}

.advertising-options-item-text {
  min-height: 60px;
  padding-top: 20px;
}

.advertising-options-hightlight {
  padding: 10px 20px;
  font-weight: 600;
  background-color: var(--secondary-color);
  color: var(--ground_color);
  text-align: center;
}

.advertising-options-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.adv-options-line {
  width: 120px;
  height: 130px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px;
}

.adv-options-image {
  height: 70px;
  width: 70px;
}

.adv-options-images-text {
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.adv-options-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-color);
  border: 2px solid var(--primary-color);
  padding: 10px 10px;
  margin-top: 20px;
  cursor: pointer;
}

.adv-options-button-text {
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 600;
  margin-right: 10px;
}

.adv-options-button:hover {
  background-color: var(--ground_color);
}

.adv-options-button:focus {
  outline: none;
}

@media (min-width: 1024px) {
  .adv-options-modal {
    max-width: 800px;
  }
}

@media (min-width: 1150px) {
  .adv-options-modal {
    max-width: 900px;
  }
}

@media (min-width: 1300px) {
  .adv-options-modal {
    max-width: 1100px;
  }
}
