.helpForm {
  position: fixed;
  visibility: visible;
  opacity: 1;
  bottom: 80px;
  right: 10px;
  background-color: var(--ground_color);
  border-radius: 10px;
  box-shadow: 1px 2px 4px 1px var(--disable-color);
  transition: opacity 0.3s ease-in-out;
}

.helpFormBody {
  display: grid;
  justify-items: center;
  padding: 10px;
}

.helpFormDisabled {
  visibility: hidden;
  opacity: 0;
}

.helpButtonHeader {
  padding: 10px 0;
  background-color: var(--secondary-color);
  width: 100%;
  text-align: center;
  color: var(--ground_color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.helpButton {
  position: fixed;
  display: flex;
  bottom: 15px;
  right: 15px;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--secondary-color);
  z-index: 10;
  box-shadow: 0 3px 4px rgba(33, 33, 33, 0.2);
  border: none;
  cursor: pointer;
}

.helpButton:hover {
  background-color: var(--secondary-hover);
}

.helpButtonList {
  margin-top: 20px;
}

.helpButtonClose {
  background-color: var(--ground_color);
}

.helpButtonClose:hover {
  background-color: #e4e0e0;
}

.helpButtonIcon {
  color: var(--ground_color);
  font-size: 30px;
}

.helpButtonCloseIcon {
  font-size: 30px;
}

.helpButtonCloseIcon path {
  stroke: #596067;
}

.helpButtonSubmit {
  margin-bottom: 15px;
  margin-top: 20px;
}

.helpButtonTextContainer {
  width: 300px !important;
}

.helpFormUser {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
  column-gap: 15px;
}

.helpUserAvatar {
  width: 50px;
  height: auto;
  border-radius: 50%;
}

.helpUserIconBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--axis-background);
}

.helpUserIcon {
  font-size: 20px;
}

.helpFormUserInfo {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.helpFormUserName {
  font-weight: 600;
  font-size: 14px;
}

.helpFormUserEmail {
  font-size: 13px;
}

@media (min-width: 1024px) {
  .helpForm {
    right: 80px;
    bottom: 70px;
  }
}
