.modalContainer {
  height: fit-content;
}
.modalBody {
  overflow-y: auto !important;
  padding-top: 0 !important;
}
.modalHeaderContainer {
  padding: 0 !important;
  border-bottom: 0 !important;
}
.modalHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 8px 5px;
  background-color: var(--eula-header);
  font-weight: 600;
}

.modalContainerSuccess {
  height: fit-content;
  margin-top: 200px;
}

.logoContainer {
  margin: auto 0;
  display: flex;
  width: 140px;
  height: auto;
}
.verticalLine {
  height: 2px;
  width: 50px;
  background-color: var(--disable-color);
  margin: 5px;
}
.modalTitle {
  text-align: center;
  margin: auto 0;
  font-size: 22px;
  font-weight: 500;
}

.emailIcon {
  width: auto;
  height: 40px;
  margin-right: 15px;
}

.emailBox {
  width: 100%;
  min-height: 70px;
  background-color: #ee7a01;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: var(--ground_color);
  font-weight: 500;
  font-size: 20px;
  margin-top: 5px;
}

.bodyBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subscription {
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 14px;
  user-select: none;
  margin-top: 20px;
}

.bodyBoxContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 15px;
  margin-top: 30px;
  margin-left: 15px;
  margin-right: 15px;
}
.formRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 600;
  column-gap: 30px;
}
.formRowLabel {
  width: 80px;
  text-align: left;
}
.modalInput {
  width: 250px !important;
}
.modalButton {
  margin-top: 30px;
  margin-bottom: 10px;
}

@media (min-width: 450px) {
  .modalHeader {
    flex-direction: row;
  }

  .verticalLine {
    display: flex;
    border-left: 2px solid var(--disable-color);
    height: 35px;
    margin: auto 20px;
    width: 0;
  }
}

@media (min-width: 768px) {
  .modalContainer {
    width: 600px;
  }
}
