.container {
  position: absolute;
  background-color: var(--axis-background);
  font-size: 13px;
  font-weight: 500;
  padding: 5px 7px;
  border-radius: 3px;
  box-shadow: 1px 1px 2px var(--disable-color);
  max-width: 280px;
  z-index: 10000;
  animation: fadeIn 0.4s;
  line-height: 1.2;
  left: 0;
  top: 0;
}

.fadeEffect {
  opacity: 0;
  animation: fadeOut 0.4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
