/* LQS Page Styles  */

.lqs-body {
  display: grid;
  grid-gap: 30px;
}

.lqs-date-pickers {
  display: flex;
  align-items: center;
  margin: 10px;
}

.lqs-days-pickers {
  margin: 10px;
}

.lqs-date-picker {
  margin-right: 10px !important;
  margin-left: 10px !important;
  width: 150px;
  color: var(--light-color) !important;
}

.lqs-chart-icon {
  margin-left: 20px;
}

/* LQS report BY ITEM table */
.lqs-filter-search {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-right: 20px;
}

.lqs-problem-select {
  margin-left: 10px;
}

.lqs-table-container {
  justify-self: center;
  display: grid;
}

/* END LQS report BY ITEM table */

.lqs-button-container {
  display: flex;
  margin-left: 30px;
  width: 100px;
  height: 40px;
  align-items: center;
}

.table-name {
  text-align: left !important;
}

/* .filters-dashboard-list {
  margin-top: 10px;
} */

.filters-checkbox-container {
  margin-right: 10px;
}

/* Ebay LQS styles */
.sqs-header-box {
  text-align: center;
  margin-bottom: 60px;
}

.sqs-header-btn {
  border: none;
  background-color: #ffc658;
  border-radius: 4px;
  padding: 7px 15px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: var(--primary-color);
  font-size: 14px;
  margin: 10px;
  width: 152px;
  min-width: 152px;
}

/* LQS switch */

/* .switch-input:checked + .switch-slider {
  background-color: #2196f3;
}

.switch-input:focus + .switch-slider {
  box-shadow: 0 0 1px #2196f3;
} */

@media (min-width: 768px) {
  .lqs-filter-search {
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .lqs-date-filter {
    margin-left: 80px;
  }

  .lqs-charts-frame {
    flex-direction: row;
  }

  .lqs-filter-search {
    margin-right: 30px;
  }

  .lqs-chart-box {
    margin-bottom: 0;
  }
}
