.logs-header-text {
  text-align: center;
  margin-bottom: 20px;
}

.logs-header-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.logs-date-pickers {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.logs-tabs-container {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--disable-color);
  margin-bottom: 20px;
}

.logs-tab-container {
  padding: 10px 30px;
  text-align: center;
  font-weight: 600;
  color: var(--primary-color);
}

.logs-tab-container:hover {
  cursor: pointer;
  color: var(--disable-color);
}

.logs-tab-active {
  border: 1px solid var(--disable-color);
  border-bottom: 1px solid var(--ground_color);
  margin-bottom: -1px;
}

.logs-tab-active:hover {
  cursor: auto;
  color: var(--primary-color);
}

.logs-users-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.logs-select-users {
  width: 350px;
  margin: 10px;
}

.logs-select-page {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  margin: 10px;
}

.logs-users-body {
  display: grid;
  border: 1px solid var(--disable-color);
  border-bottom: 0;
  margin-top: 10px;
  overflow-x: auto;
}

.logs-table-body {
  display: grid;
  grid-auto-rows: max-content;
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.logs-table-header-text {
  font-weight: 600;
  font-size: 15px !important;
  background-color: var(--axis-background);
}

.logs-table-row {
  display: grid;
  grid-template-columns: 200px 200px 100px 130px 150px;
  font-size: 13px;
  border-bottom: 1px solid var(--disable-color);
}

.logs-table-cell {
  display: flex;
  align-items: center;
  padding: 10px;
}

.logs-table-cell-center {
  justify-content: center;
}

@media (min-width: 1024px) {
  .logs-table-row {
    grid-template-columns: 1.5fr 2fr 1fr 1fr 1fr;
  }
}
