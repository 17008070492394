/* Admin Dashboard Styles */
.admin-home-container {
  display: grid;
  grid-gap: 30px;
  padding: 40px 0 0 0 !important;
  justify-content: center;
}

.admin-home-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 220px;
  width: 250px;
  background-color: var(--axis-background);
  border-radius: 5px;
  transition: box-shadow 0.3s;
}

.admin-home-box:hover {
  box-shadow: 0 3px 11px rgba(33, 33, 33, 0.2);
}

.admin-home-header {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}

.admin-home-react-icon {
  width: 60px;
  height: auto;
  margin-bottom: 20px;
}

.admin-home-icon {
  width: 60px;
  height: auto;
  margin-bottom: 20px;
}

.admin-home-icon path {
  fill: var(--primary-color);
}

/* Vendor Dashboard styles*/

.dashboard-lqs-header {
  display: grid;
  justify-content: center;
  margin-top: 30px;
}

.dashboard-lqs-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
  padding-top: 20px;
}

.dashboard-map-row {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.test-nav {
  background-color: none;
}

.dashboard-icon {
  width: 30px;
  height: auto;
}

.dashboard-nav-icon,
.dashboard-nav-icon-disabled {
  width: 30px;
  height: auto;
}

.dashboard-icon path {
  fill: var(--primary-color);
}

.dashboard-nav-icon path {
  fill: var(--ground_color);
}

.dashboard-nav-icon-disabled path {
  fill: var(--disable-color);
}

.Mui-selected .dashboard-icon path {
  fill: var(--secondary-color);
}

.dashboard-tab-text {
  font-size: 10px;
  text-align: center;
}

.dashboard-selected-tab {
  color: var(--secondary-color);
}

.dashboard-divider {
  height: 3px !important;
}

.dashboard-nav-hover:hover {
  background-color: var(--secondary-color) !important;
}

.dashboard-nav-disabled {
  color: var(--disable-color) !important;
  cursor: not-allowed !important;
}

/* Adv tab styles */
.dashboard-adv-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: center;
}

.dashboard-adv-box {
  margin: 20px 10px;
  width: 320px;
}

.dashboard-adv-header {
  padding-left: 30px;
  background-color: var(--chart-green-color);
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--ground_color);
}

.dashboard-adv-row {
  display: flex;
}

.dashboard-adv-row-header {
  display: flex;
  background-color: var(--axis-background);
  font-size: 15px;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  width: 180px;
  border-left: 1px solid var(--table-border-color);
  border-right: 1px solid var(--table-border-color);
  border-bottom: 1px solid var(--table-border-color);
}

.dashboard-adv-row-value {
  font-size: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  width: 140px;
  border-right: 1px solid var(--table-border-color);
  border-bottom: 1px solid var(--table-border-color);
}

.dashboard-adv-info {
  margin: 30px auto;
  padding: 25px 40px;
  background-color: var(--axis-background);
  border: 1px solid var(--table-border-color);
  border-radius: 5px;
  width: fit-content;
}

.dashboard-adv-contact {
  font-weight: 600;
  text-decoration: underline;
}

.dashboard-adv-nodata {
  display: grid;
}

.dashboard-adv-nodata-header {
  width: 80%;
  margin: 20px 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--ground_color);
  text-align: center;
  font-weight: 600;
  background-color: var(--secondary-color);
}

/* End Adv tab styles */
.dashboard-sales-icon {
  width: 60px;
  height: auto;
  justify-self: center;
}

/* Dashboard SQS tab */
.dashboard-sqs-btn {
  background-color: #ee7a00;
  cursor: pointer;
  border: none;
  border-radius: 17px;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 8px 30px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.dashboard-sqs-btn:hover {
  background-color: #ee7a00;
}

.dashboard-sqs-btn-icon {
  margin-left: 5px;
  font-size: 24px;
}

@media (min-width: 768px) {
  .dashboard-adv-box {
    width: 340px;
    margin: 20px 30px;
  }

  .dashboard-adv-row-header {
    width: 200px;
  }
}

@media (min-width: 1285px) {
  .left-header {
    padding-top: 27px;
  }
}

@media (min-width: 1620px) {
  .dashboard-adv-nodata-header {
    width: 67%;
  }
}
