/* Footer */
.footer {
  align-self: end;
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.tooltip-container {
  max-width: 250px;
  padding: 10px;
  background-color: var(--light-color);
  font-weight: 500;
}

.tooltip-container-long {
  max-width: 350px;
  max-height: 350px;
  pointer-events: auto;
  overflow-y: auto;
  padding: 10px;
  background-color: var(--ground_color);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  font-weight: 500;
}

.tooltip-header {
  text-align: center;
  color: black;
  margin-bottom: 5px;
}

.tooltip-row {
  display: flex;
  width: 100%;
  font-size: 13px;
  margin-top: 3px;
}

.tooltip-row-name {
  width: 200px;
}

/* Vendor Dashboard Page styles */
.admin-logo {
  justify-self: center;
}

.admin-logo img {
  max-width: 200px;
}

.select-brand {
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
  margin: 30px auto;
}

.select-brand > h2 {
  margin-right: 10px;
}

.main-icons .main-icon {
  display: grid;
  justify-items: center;
  margin: 20px;
  border: 5px solid var(--light-color);
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 1px 3px #ccc, 0 1px 2px #ccc;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.main-icons .main-icon:hover {
  animation-name: example;
  animation-duration: 0.5s;
  box-shadow: 0 7px 14px #ccc, 0 5px 5px #ccc;
  cursor: pointer;
}

.report-not-available {
  display: grid;
  justify-items: center;
  margin: 20px;
  border: 5px solid var(--light-color);
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 1px 3px #ccc, 0 1px 2px #ccc;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: not-allowed;
}

.marketplace {
  width: 100%;
  border-bottom: 1px solid var(--primary-color);
  padding-left: 10px;
}

.marketplace-text {
  text-align: left;
  font-size: 17px;
  font-weight: 600px;
  color: var(--primary-color);
}

.admin-header {
  position: fixed;
  height: 64px;
  top: 0;
  width: 100%;
  z-index: 3;
  background-color: var(--ground_color);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.admin-icons-menu {
  justify-self: center;
  display: flex;
  align-items: center;
}

/* Drawer styles */
.drawer-box {
  display: flex;
}

.drawer-top {
  position: fixed;
  display: flex;
  top: 0;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  height: 60px;
  background-color: var(--ground_color);
  z-index: 10;
  box-shadow: 0 4px 4px -2px var(--disable-color);
}

.drawer-left {
  display: none;
}

.drawer-left-li {
  height: 45px !important;
  padding: 5px 14px !important;
}

.drawer-left-close {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.drawer-left-open {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
}

.drawer-left-nav {
  margin-left: 10px;
}

.active-menu {
  display: block;
}

.drawer-user {
  margin-left: 20px;
}

.drawer-user-name {
  display: none;
  font-size: 15px;
}

.drawer-logo {
  display: flex;
  flex: 1;
  justify-content: center;
}

.drawer-logo img {
  max-width: 200px;
  min-width: 60px;
}

.drawer-icons-menu {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.drawer-home-container {
  min-height: 100vh;
  display: grid;
  padding-top: 20px;
  padding-left: 100px;
  padding-right: 50px;
}

.home-container-breadcrumbs {
  min-height: 78vh;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.not-report {
  margin-top: 70px;
}

.drawer-box-container {
  min-height: 100vh;
  display: grid;
  padding-top: 80px;
  padding-left: 100px;
  padding-right: 50px;
}

.home-clear-container {
  min-height: 90vh;
  padding-top: 80px;
  padding-left: 10px;
  padding-right: 10px;
}

.drawer-home-body {
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brand-selection-container {
  position: fixed;
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  top: 64px;
  left: 100px;
  padding-left: 180px;
  background-color: var(--ground_color);
}

/* Alerts */
.alert-container {
  display: grid;
  grid-gap: 10px;
  top: 20px;
  position: fixed;
  right: 20px;
  z-index: 1000;
  max-width: 400px;
}

.admin-header .user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .home-container-breadcrumbs {
    padding-top: 20px;
    padding-left: 100px;
    padding-right: 50px;
  }

  .home-clear-container {
    padding-left: 100px;
    padding-right: 50px;
  }

  .drawer-left-nav {
    display: none;
  }

  .drawer-left-close {
    display: none;
  }

  .admin-header {
    display: grid;
    height: 64px;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
  }

  .alert-container {
    top: 80px;
  }

  .drawer-user {
    width: 250px;
    margin-left: 80px;
  }

  .drawer-user-name {
    display: block;
  }

  .drawer-left {
    display: block;
    top: 0;
  }

  .drawer-icons-menu {
    min-width: 180px;
  }
}

/* Chat Styles */
.chat-container {
  display: flex;
  background-color: var(--light-color);
  width: 850px;
  height: 600px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
}

.chat-nav-container {
  border: 2px solid var(--primary-color);
}

.chat-nav-header {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 10px 20px;
  border-bottom: 2px solid var(--primary-color);
}

.chat-body-container {
  flex-grow: 1;
  border-right: 2px solid var(--primary-color);
  border-top: 2px solid var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}
.chat-body-header {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 10px 20px;
  border-bottom: 2px solid var(--primary-color);
}

.chat-body {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.chat-body-messages {
  padding: 10px;
  background-color: var(--ground_color);
  border-radius: 10px;
  height: 400px;
  width: 470px;
  overflow-y: auto;
}

.chat-nav-list {
  height: 500px;
  width: 320px;
  margin-top: 10px;
  overflow-y: auto;
}

.chat-nav-list li {
  height: 70px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px;
  padding: 10px;
  background-color: var(--ground_color);
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 1px 1px 2px var(--disable-color);
}

.chat-nav-list li:hover {
  box-shadow: 3px 3px 5px var(--disable-color);
}

.nav-list-info {
  width: 190px;
}

.nav-list-icon {
  height: 30px !important;
  width: 40px !important;
  margin: 10px;
}

.nav-list-selected {
  background-color: #e4f7fb !important;
}

.nav-list-title {
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}

.nav-list-subtitle {
  font-size: 11px;
}

.nav-list-subtitle-box {
  display: flex;
  flex-wrap: wrap;
}

.chat-send-box {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.chat-input-message {
  width: 350px;
  height: 40px;
  padding: 5px;
  border: 1px solid var(--disable-color);
  border-radius: 5px;
  font-size: 14px;
  font-family: inherit;
  resize: none;
}

.chat-input-message:focus {
  outline: none;
}

.chat-send-button {
  border: none;
  height: 40px;
  border-radius: 10px;
  padding: 10px 20px;
  background-color: var(--secondary-color);
  color: var(--ground_color);
  cursor: pointer;
  margin-left: 20px;
  font-weight: 600;
  box-shadow: 2px 2px 5px var(--disable-color);
}

.chat-send-button:hover {
  background-color: var(--secondary-hover);
  box-shadow: 3px 3px 5px var(--disable-color);
}

.chat-message-own-box {
  width: fit-content;
  max-width: 400px;
  margin-left: auto;
  margin-bottom: 10px;
}

.chat-message-box {
  margin-right: 50px;
  margin-bottom: 10px;
}

.chat-message-name {
  color: var(--chart-blue-color);
  font-size: 11px;
}

.chat-message {
  display: flex;
  align-items: center;
}

.chat-message-own {
  border-radius: 5px;
  padding: 10px 20px;
  background-color: #e7fecc;
}

.chat-message-user {
  max-width: 340px;
  border-radius: 5px;
  padding: 10px 20px;
  background-color: #cdd5e2;
}

.chat-message-user-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.chat-message-text {
  font-size: 14px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.chat-message-date {
  margin-left: 20px;
  margin-top: 5px;
  font-size: 11px;
  text-align: right;
}

@media (min-width: 1150px) {
  .chat-container {
    width: 1000px;
  }

  .chat-body-messages {
    width: 620px;
  }

  .chat-message-user {
    max-width: 450px;
  }

  .chat-message-own-box {
    max-width: 450px;
  }
}

@media (min-width: 1024px) {
  .chat-input-message {
    height: 90px;
  }

  .chat-container {
    height: 650px;
  }
}

/* Contacts Styles */
.contacts-container {
  min-height: 90vh;
  padding-top: 100px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contacts-box {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 250px;
  align-items: center;
  justify-items: center;
  margin: 10px;
  padding: 10px;
  background-color: var(--axis-background);
}

.contacts-name {
  width: 100%;
  font-weight: 600;
  text-align: center;
}

.contacts-team-role {
  padding-left: 10px;
  padding-right: 10px;
  color: var(--disable-color);
  text-align: center;
}

.contacts-avatar-box {
  padding: 10px;
}

.contacts-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.contacts-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 140px;
  color: var(--primary-color) !important;
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  padding: 5px 10px;
}

.contacts-button:hover {
  color: var(--secondary-color) !important;
  border: 2px solid var(--secondary-color);
}

.contacts-button-icon {
  margin-right: 10px;
  width: 24px;
  height: auto;
}

@media (min-width: 768px) {
  .contacts-container {
    padding-left: 100px;
  }

  .contacts-name {
    margin-left: 10px;
    text-align: left;
  }

  .contacts-box {
    grid-template-rows: 100px 50px;
    grid-template-columns: 200px 200px 150px;
  }
}

@media (min-width: 1024px) {
  .contacts-box {
    grid-template-rows: none;
    grid-template-columns: 120px 200px 150px 200px 200px;
  }
}

/* Sellers page */

.sellers-container {
  display: grid;
  grid-gap: 15px;
  justify-items: center;
}

.sellers-tabs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: var(--primary-color);
  padding-top: 10px;
}

.sellers-tab-container {
  min-width: 160px;
  color: var(--ground_color);
  padding: 15px 40px;
  text-align: center;
  font-weight: 600;
}

.sellers-tab-container:hover {
  cursor: pointer;
  color: var(--primary-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--disable-color);
}

.seller-tab-active {
  color: var(--primary-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--ground_color);
}

.seller-tab-active:hover {
  cursor: auto;
  background-color: var(--ground_color);
}

.sellers-tabs {
  justify-self: center;
}

.sellers-tab-box {
  padding: 5px 10px;
}

.sellers-tab-header {
  font-size: 18px;
  font-weight: 600;
}

.sellers-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.sellers-date-pickers {
  display: flex;
  align-items: center;
}

.sellers-body {
  padding: 10px;
}

.sellers-total-header {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
}

.sellers-total-cell {
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 20px;
}

.sellers-chart-header {
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  background-color: var(--axis-background);
}

.sellers-chart {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 50px;
}

.sellers-chart-box {
  height: 340px;
  padding: 10px;
  background-color: var(--axis-background);
}

.offers-table-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.offers-date-pickers {
  margin-right: 20px;
  margin-bottom: 10px;
}

.offers-table-icon {
  color: var(--error-color);
  width: 24px;
  height: auto;
}

.offers-table-amz-icon {
  width: 20px;
  min-width: 20px;
  height: auto;
  justify-self: center;
  margin-right: 5px;
  margin-left: 5px;
}

.offers-filter-amz-discount {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 1024px) {
  .sellers-chart {
    flex-direction: row;
  }

  .offers-date-pickers {
    margin-right: 100px;
    margin-bottom: 0;
  }

  .offers-table-nav {
    justify-content: left;
  }

  .table-container {
    grid-template-columns: auto 1fr;
  }

  .sellers-filter-container {
    display: block !important;
    margin-top: 0px !important;
    margin-left: 20px;
  }

  .offers-filter-amz-discount {
    margin-top: 60px;
    margin-bottom: 30px;
    margin-left: 10px;
    margin-right: 0;
  }

  .offers-filter-amz-discount span {
    max-width: 150px;
  }

  .offers-chart-buttons {
    margin-left: 10px;
  }
}

/* User Info styles */

.user-container {
  display: grid;
  grid-gap: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 84px;
}

.user-container-new {
  display: grid;
  grid-gap: 20px;
  margin-top: 80px;
}

.user-contacts-row-text {
  text-align: center;
  font-size: 13px;
}

@media (min-width: 768px) {
  .user-container {
    padding-left: 80px;
    padding-right: 50px;
  }
  .footer {
    padding-left: 100px;
    padding-right: 50px;
  }
}

@media (min-width: 1024px) {
  .sellers-body {
    max-height: 65vh;
    overflow-y: auto;
  }

  .user-container {
    max-height: 80vh;
    overflow-y: auto;
  }
}

/* Admin Users, Vendors, Brands Page Styles*/

.admin-users-container {
  display: grid;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
}

.admin-items-header {
  display: grid;
  align-items: center;
  grid-gap: 10px;
}

.admin-filter-search {
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .admin-home-container {
    grid-gap: 20px;
    grid-template-columns: auto auto;
  }

  .admin-users-container {
    margin-left: 135px;
    margin-right: 115px;
  }

  .admin-items-header {
    grid-template-columns: 1fr 1fr 370px;
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media (min-width: 1024px) {
  .admin-home-container {
    grid-gap: 20px;
    grid-template-columns: auto auto auto;
  }
}

@media (min-width: 1300px) {
  .admin-home-container {
    grid-gap: 20px;
    grid-template-columns: auto auto auto auto;
  }
}

/* breadcrumbs */
.breadcrumbs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 64px;
  padding-left: 20px;
  background-color: var(--axis-background);
}

.breadcrumbs-brand-select {
  display: flex;
  align-items: center;
  margin: 10px;
}

.breadcrumbs-headers-column {
  display: grid;
  grid-gap: 2px;
  align-items: center;
  margin: 10px;
}

.breadcrumbs-header {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 600;
}

.breadcrumbs-name-box {
  display: flex;
  align-items: center;
  min-height: 28px;
}

.breadcrumbs-link {
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
}

.breadcrumbs-icon-link {
  color: var(--primary-color) !important;
  margin-left: 10px;
}

.breadcrumbs-icon-link:hover {
  color: var(--disable-color) !important;
}

@media (min-width: 768px) {
  .breadcrumbs-container {
    flex-direction: row;
    height: 70px;
  }

  .breadcrumbs-brand-select {
    margin-left: 130px;
  }

  .breadcrumbs-headers-column {
    margin-left: 50px;
    margin-right: 0;
  }
}

@media (min-width: 1024px) {
  .breadcrumbs-headers-column {
    min-width: 400px;
  }
}

/* Sellers table */

.sellers-filter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
}

.sellers-filter-container-li {
  width: 100px;
  border-radius: 10px;
  font-weight: 600;
  text-align: center;
  padding: 10px 20px;
  margin: 10px;
}

.sellers-table-header {
  display: grid;
  grid-template-rows: 30px 55px;
  grid-template-columns: 150px repeat(3, 100px) repeat(7, 110px);
  font-size: 14px;
  font-weight: 600;
  background-color: var(--axis-background);
  border-top: 1px solid var(--primary-color);
  border-left: 1px solid var(--primary-color);
  margin-right: 13px;
}

.sellers-table-header-used {
  grid-template-columns: 150px repeat(3, 100px) repeat(9, 110px) !important;
}

.sellers-offer-columns {
  grid-column: 5/8;
  grid-row: 1/2;
}

.sellers-offer-columns-used {
  grid-column: 5/9;
}

.sellers-map-columns {
  grid-column: 8/10;
  grid-row: 1/2;
}

.sellers-map-columns-used {
  grid-column: 9/12;
}

.sellers-table-body {
  max-height: 400px;
  overflow-y: auto;
  font-size: 12px;
  border-left: 1px solid var(--disable-color);
}

.sellers-table-row {
  display: grid;
  grid-template-columns: 150px repeat(3, 100px) repeat(7, 110px);
}

.sellers-table-row-used {
  display: grid;
  grid-template-columns: 150px repeat(3, 100px) repeat(9, 110px);
}

.sellers-table-row:hover {
  background-color: var(--ground_color);
}

/* Sellers Modal Table */
.sellers-offers-modal {
  max-width: 600px;
  height: 630px;
}

.sellers-offers-table-header {
  display: grid;
  font-size: 13px;
  font-weight: 600;
  background-color: var(--axis-background);
  border-top: 1px solid var(--primary-color);
  border-left: 1px solid var(--primary-color);
  margin-right: 13px;
}

.sellers-offers-table-row {
  display: grid;
}

.sellers-offers-table-row:hover {
  background-color: var(--ground_color);
}

@media (min-width: 1024px) {
  .sellers-offers-modal {
    max-width: 900px;
  }
}

@media (min-width: 1300px) {
  .sellers-offers-modal {
    max-width: 1220px;
  }
}

/* All tables */
.table-container {
  display: grid;
  margin-top: 15px;
}

.table-box {
  display: grid;
  overflow-x: auto;
  margin-bottom: 10px;
  grid-auto-rows: max-content;
}

.table-body-cell {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid var(--disable-color);
  border-right: 1px solid var(--disable-color);
  background-color: var(--axis-background);
  text-align: center;
  word-break: break-word;
}

.table-body-cell-no-border {
  border: none;
  background-color: white;
}

.table-body-cell-last {
  border-bottom: none;
}

.table-cell-noselect {
  user-select: none;
}

.table-body-cell-active {
  justify-content: left;
  padding-left: 35px;
  padding-right: 20px;
}

.table-header-cell {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 7px;
  border-bottom: 1px solid var(--primary-color);
  border-right: 1px solid var(--primary-color);
  text-align: center;
}

.table-cell-align-left {
  justify-content: left !important;
  text-align: left !important;
}

.table-total-header {
  font-weight: 700;
  font-size: 14px;
  border-left: 1px solid var(--disable-color);
}

.editable-body-cell {
  position: relative;
}

.active-modal-cell {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}

.active-modal-cell:hover {
  background-color: var(--ground_color);
  color: var(--secondary-color);
}

.active-modal-cell:hover .visibility-container {
  color: var(--secondary-color);
}

.visibility-container {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  height: 15px;
  color: var(--disable-color);
}

/* END All tables */

.table-two-rows {
  grid-row: 1/3;
}

.table-offer-columns {
  grid-column: 5/8;
  grid-row: 1/2;
}

.table-fba-columns {
  grid-column: 8/11;
  grid-row: 1/2;
}

.table-fbm-columns {
  grid-column: 11/14;
  grid-row: 1/2;
}

.table-map-columns {
  grid-column: 14/17;
  grid-row: 1/2;
}

/* Compare Reports table */
.compare-table-header {
  display: grid;
  grid-template-rows: 30px 40px;
  grid-template-columns: 120px 100px 70px 70px repeat(4, 95px 95px 65px);
  font-size: 14px;
  font-weight: 600;
  background-color: var(--axis-background);
  border-top: 1px solid var(--primary-color);
  border-left: 1px solid var(--primary-color);
  margin-right: 13px;
}

.compare-table-body {
  max-height: 400px;
  overflow-y: auto;
  font-size: 12px;
  border-left: 1px solid var(--disable-color);
}

.compare-table-row {
  display: grid;
  grid-template-columns: 120px 100px 70px 70px repeat(4, 95px 95px 65px);
}

.compare-table-row:hover {
  background-color: var(--ground_color);
}

/* Sales report */

.sales-chart-frame {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.sales-chart-box {
  padding: 10px;
  height: 420px;
  margin-right: 20px;
  background-color: var(--axis-background);
}

.sales-datepicker-arrow {
  width: 15px !important;
  height: 15px !important;
  margin-right: 10px;
  margin-left: 10px;
  align-self: center;
}

.sales-param-container {
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  margin-right: 10px;
  margin-top: 10px;
  text-align: center;
}

.sales-param-primary {
  background-color: rgba(47, 52, 64, 0.3);
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.sales-param-secondary {
  background-color: rgba(221, 126, 67, 0.3);
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.sales-param-blue {
  background-color: rgba(87, 126, 203, 0.3);
  border-color: var(--chart-blue-color);
  color: var(--chart-blue-color);
}

.sales-param-gray {
  background-color: rgba(246, 246, 246, 0.3);
  border-color: var(--disable-color);
  color: var(--disable-color);
}

.sales-param-red {
  background-color: rgba(244, 7, 7, 0.3);
  border-color: var(--error-color);
  color: var(--error-color);
}

.sales-param-green {
  background-color: rgba(145, 197, 89, 0.3);
  border-color: var(--chart-green-color);
  color: var(--chart-green-color);
}

.sales-param-orange {
  background-color: rgba(255, 199, 86, 0.3);
  border-color: var(--chart-orange-color);
  color: var(--chart-orange-color);
}

.sales-param-name {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
}

.sales-param-amount {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-color);
}

@media (min-width: 1024px) {
  .sales-chart-frame {
    flex-direction: row;
  }
}

/* Chart legent styles */
.chart-box {
  height: 340px;
  padding: 10px;
  background-color: var(--axis-background);
}

.chart-legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.chart-legend-li {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 20px;
  border-radius: 15px;
  line-height: 1.5;
}

.chart-text {
  font-size: 12px;
  font-weight: 600;
}

.chart-buttons {
  display: inline;
}

.chart-icon {
  margin-left: 20px;
}

@media (min-width: 1024px) {
  .chart-legend {
    display: block;
    margin-top: 0px;
    margin-left: 30px;
  }

  .chart-legend-li {
    margin-right: 0;
  }
  .chart-buttons {
    display: flex;
  }
}

/* Skeleton containers */

.search-container {
  margin-left: 30px;
  margin-right: 30px;
  width: 250px;
  height: 30px !important;
}

.list-container {
  width: 200px;
  height: 40px !important;
}

.select-loader-container {
  width: 200px;
  height: 30px !important;
}

.dates-container {
  width: 130px;
  height: 30px !important;
}

/* Help page */
.help-container {
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.help-header {
  margin-top: 20px;
}

.help-description {
  max-width: 800px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.help-video-box {
  display: flex;
  flex-direction: column;
  margin: 30px;
  width: 300px;
}

.help-pdf-icon {
  font-size: 30px !important;
  cursor: pointer;
  margin-top: 20px;
}
.help-pdf-icon:hover path {
  stroke: var(--secondary-color) !important;
}

.help-pdf-icon-disabled {
  font-size: 30px !important;
  margin-top: 20px;
  cursor: not-allowed;
}

.help-video-text {
  font-size: 17px;
  margin-top: 10px;
  height: 40px;
  display: flex;
  align-items: center;
}

.help-video-description {
  margin-top: 5px;
  font-size: 16px;
  height: 100px;
  display: flex;
  align-items: center;
}

.help-video-preview {
  height: auto;
  width: 100%;
  margin-top: 5px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
}

.help-video-preview:hover {
  box-shadow: 3px 3px 5px var(--disable-color);
}

.help-video-preview-disabled {
  height: auto;
  width: 100%;
  margin-top: 5px;
  cursor: not-allowed;
}

.help-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.help-video-player {
  margin-top: 20px;
  width: 100%;
}

.help-download-link {
  width: min-content;
}

@media (min-width: 768px) {
  .help-container {
    justify-content: unset;
  }
}
