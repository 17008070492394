.prices-home-body {
  min-height: 75vh;
}

.prices-pay-half {
  display: grid;
  justify-content: center;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: var(--axis-background);
}

.prices-pay-half-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

.prices-pay-half-box {
  display: grid;
  margin: 1px;
  width: 140px;
}

.prices-pay-half-name {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.prices-pay-half-scale {
  width: 140px;
  margin-top: 5px;
  height: 4px;
  background-color: var(--primary-color);
}

.prices-scale-green {
  background-color: var(--success-color);
}

.prices-amz-aditional {
  margin-top: 40px;
}

.prices-tab-box {
  padding: 10px 20px;
}

.prices-button-amz {
  margin-top: 20px;
}

.table-problems-column {
  width: 120px;
}

.prices-slider-box {
  position: relative;
  display: none;
  min-width: 700px;
  margin-top: 30px;
  margin-bottom: 30px;
}

/* Prices Tables */

.align-center {
  justify-content: center;
}

.green-color {
  color: var(--ground_color);
  background-color: var(--chart-green-color) !important;
}

.orange-color {
  color: var(--ground_color);
  background-color: var(--chart-orange-color) !important;
}

.blue-color {
  color: var(--ground_color);
  background-color: var(--chart-blue-color) !important;
}

.border-bottom {
  border-bottom: 0 !important;
}

.border-left {
  border-left: 0 !important;
}

.border-right {
  border-right: 0 !important;
}

.prices-list-box-wide {
  width: 280px;
}

@media (min-width: 768px) {
  .prices-slider-box {
    display: block;
  }
}

@media (min-width: 1285px) {
  .prices-slider-potential::-webkit-slider-thumb {
    top: 22px;
  }

  .prices-slider-potential::-moz-range-thumb {
    top: 22px;
  }
}
