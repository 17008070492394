.navBox {
  display: flex;
}

.navTop {
  position: fixed;
  display: flex;
  top: 0;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  height: 60px;
  background-color: var(--ground_color);
  z-index: 10;
  box-shadow: 0 4px 4px -2px var(--disable-color);
}

.navLeft {
  position: fixed;
  display: none;
  top: 0;
  height: 100%;
  width: 60px;
  z-index: 11;
  background-color: var(--primary-color);
  color: var(--ground_color);
  overflow-x: hidden;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.navLeftFull {
  width: 270px;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 11;
  background-color: var(--primary-color);
  color: var(--ground_color);
}

.navLeftBody {
  width: 60px;
  height: 85%;
  overflow-y: auto;
  scrollbar-width: none;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.navLeftBody::-webkit-scrollbar {
  display: none;
}

.navLeftBodyFull {
  width: 270px;
  height: 85%;
  overflow-y: auto;
  scrollbar-width: none;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.navLeftBodyFull::-webkit-scrollbar {
  display: none;
}

.navLeftOption {
  display: flex;
  align-items: center;
  width: 270px;
  padding: 0 15px;
  height: 45px;
  min-height: 40px;
  fill: gray;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
  color: var(--ground_color);
  margin: 5px 0;
}

.navLeftOption:hover {
  background-color: var(--secondary-color);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.navLeftSublistOption {
  margin: 0;
}

.navLeftOptionList {
  display: flex;
  flex-direction: column;
  width: 270px;
  user-select: none;
}

.navLeftIcon {
  font-size: 25px !important;
  margin-right: 15px;
  color: var(--ground_color);
}

.navLeftClose {
  display: flex;
  justify-content: flex-end;
}

.navLeftArrow {
  display: none;
}

.navLeftCloseIcon {
  font-size: 35px;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.navLeftCloseIcon path {
  stroke: var(--ground_color);
}

.navLeftCloseIcon polyline {
  stroke: var(--ground_color);
}

.navTopMobile {
  display: block;
}

.activeMenu {
  display: block;
}

.dashNavReactIcon {
  width: 30px !important;
  height: auto;
  margin-right: 15px;
}

.dashNavIcon {
  width: 30px !important;
  height: auto;
  margin-right: 15px;
}

.dashNavIcon path {
  fill: var(--ground_color);
}

.dashNavIcon polygon {
  fill: var(--ground_color);
}

.dashNavIcon circle {
  fill: var(--ground_color);
}

.dashNavIconClose {
  margin-right: 0;
}

.dashNavIconOpen {
  margin-left: 15px;
}

.dashNavDisabled {
  color: var(--disable-color);
  cursor: not-allowed !important;
}

.dashNavDisabledCustom {
  color: var(--disable-color);
  cursor: not-allowed !important;
}

.dashNavDisabled:hover,
.dashNavDisabledCustom:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.dashNavDisabled path {
  fill: var(--disable-color);
}

.tabletLeftOpen {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-bottom: 12px;
  margin-top: 12px;
  margin-left: 10px;
  cursor: pointer;
}

.tabletLeftClosed {
  margin-top: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}

.navDevider {
  height: 3px;
  margin: 5px 0;
  background-color: rgba(0, 0, 0, 0.12);
}

.navListDevider {
  height: 2px;
  background-color: #576075;
}

.navTopIcon {
  font-size: 30px !important;
  cursor: pointer;
  margin: 10px;
  color: var(--primary-color);
}

.navTopIconCustom {
  vertical-align: middle;
  height: 40px;
}

.navTopIconBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--axis-background);
}

.navTopUserIcon {
  font-size: 25px;
}

.navAvatarBox {
  display: flex;
  align-items: center;
  color: var(--primary-color);
  position: relative;
}

.navUserGear {
  position: absolute;
  left: 40px;
  bottom: 0;
  width: 15px;
  height: 15px;
  background-color: var(--ground_color);
  border-radius: 50%;
}

.navAvatar {
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
}

.navUserName {
  display: none;
  font-size: 14px;
}

.navUserBox {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.navLogo {
  display: flex;
  flex: 1;
  justify-content: center;
}

.navLogo img {
  max-width: 200px;
  min-width: 60px;
  display: none;
}

.blackLink {
  color: var(--primary-color);
}

.navIconsMenu {
  position: relative;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.navChatBox {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.navChatIcon {
  font-size: 34px !important;
  cursor: pointer;
  margin-bottom: 0;
}

.navCountIcon {
  padding: 1px 5px;
  font-size: 11px;
  border-radius: 50%;
  background-color: var(--error-color);
  color: var(--ground_color);
}

.navTopIcon {
  vertical-align: middle;
  height: 40px;
}

.navTopIcon path {
  fill: var(--primary-color);
}

.navMiIcon {
  font-size: 34px !important;
  cursor: pointer;
  margin: 10px;
}

.navLeftList {
  margin: 0;
}

.navLeftListActive {
  background-color: var(--secondary-color);
}

.navList {
  display: none;
}

.navSublist {
  background-color: #4c5467;
}

.navSublistIcon {
  width: 7px;
  height: 7px;
  background-color: #bbbcbf;
  margin-left: 10px;
  margin-right: 28px;
}

.navIconImageBox {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.navIconImage {
  width: 20px;
  height: auto;
}

.navCompareTop {
  display: none;
}

.navCompareLeft {
  display: block;
}

.compareBtnMobile {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #ffc658;
  font-size: 18px;
  z-index: 100;
}

.navUserContainer {
  display: flex;
  align-items: center;
  column-gap: 10px;
  position: relative;
}

.verifyEmailBox {
  position: absolute;
  align-items: center;
  left: 105%;
  top: 7px;
  color: var(--error-color);
  background-color: #efd3d7;
  padding: 5px 10px;
  border: 1px solid #dbc9cb;
  box-shadow: 1px 1px 2px var(--disable-color);
  width: max-content;
  font-size: 14px;
  display: none;
  cursor: pointer;
}

.verifyEmailBoxProhibited {
  display: none !important;
}

.verifyEmailIcon {
  width: 20px;
  height: 20px;
  color: var(--error-color);
  margin-right: 5px;
}
.verifyEmailIconMain {
  width: 20px;
  min-width: 20px;
  height: 20px;
  color: var(--error-color);
  margin-right: 5px;
  cursor: pointer;
}
.verifyEmailIconMainProhibited {
  display: block !important;
}
.verifyEmailText {
  font-weight: 600;
  text-decoration: underline;
  margin-left: 5px;
}

@media (min-width: 400px) {
  .navLogo img {
    display: block;
  }
}

@media (min-width: 768px) {
  .compareBtnMobile {
    display: none;
  }

  .navCompareTop {
    display: block;
  }

  .navCompareLeft {
    display: none;
  }

  .navTopIconCustom {
    margin: 10px;
  }

  .navUserName {
    display: block;
  }

  .navUserBox {
    max-width: 250px;
    margin-left: 20px;
  }

  .navLeft,
  .navLeftFull {
    display: block;
  }

  .navLeftClose {
    display: none;
  }

  .navTopIcon {
    margin: 10px;
  }
  .navTopIconBox {
    margin-right: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .navLeftArrow {
    display: flex;
    justify-content: flex-end;
  }
}

@media (min-width: 1024px) {
  .navIconsMenu {
    min-width: 390px;
  }

  .navLogo {
    margin-left: 100px;
  }
}

@media (min-width: 1400px) {
  .verifyEmailBox {
    display: flex;
  }
  .verifyEmailIconMain {
    display: none;
  }
}

@media (min-width: 1600px) {
  .verifyEmailBoxProhibited {
    display: flex !important;
  }
  .verifyEmailIconMainProhibited {
    display: none !important;
  }
}
