.compareBtnBox {
  margin: 10px;
}

.compareBtn {
  border: none;
  background-color: #ffc658;
  border-radius: 4px;
  padding: 7px 15px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: var(--primary-color);
  font-size: 14px;
}

.compareBtnIcon {
  width: 18px;
  min-width: 18px;
  height: auto;
  color: var(--primary-color);
  cursor: pointer;
}

.compareBtnIcon:hover {
  color: var(--error-color);
}

.compareLink {
  color: var(--primary-color);
}
