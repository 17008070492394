.loaderContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dataLoader {
  display: inline-block;
  position: relative;
  width: var(--size);
  height: var(--size);
}
.dataLoader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: calc(var(--size) * 0.8);
  height: calc(var(--size) * 0.8);
  margin: calc(var(--size) / 10);
  border: calc(var(--size) / 10) solid var(--color);
  border-radius: 50%;
  animation: dataLoader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--color) transparent transparent transparent;
}
.dataLoader div:nth-child(1) {
  animation-delay: -0.45s;
}
.dataLoader div:nth-child(2) {
  animation-delay: -0.3s;
}
.dataLoader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes dataLoader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Skeleton */
.skeletonBox {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #f0ecf0;
  border-radius: 5px;
}

.skeletonBox::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 5s infinite;
  -webkit-animation: shimmer 5s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.tableSceletonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.tableSceletonBox {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
}

.tableSceleton {
  margin: 10px;
  width: 130px;
  height: 20px !important;
  border-radius: 10px !important;
}

@media (min-width: 768px) {
  .tableSceleton {
    margin: 20px;
    width: 150px;
  }
}

/* Main loader */
.mainLoaderBox {
  display: grid;
  height: 60vh;
  justify-content: center;
  align-content: center;
}
@keyframes mainLoader {
  0% {
    top: 45px;
    left: 45px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1.5px;
    left: -1.5px;
    width: 93px;
    height: 93px;
    opacity: 0;
  }
}
.mainLoader div {
  position: absolute;
  border-width: 5px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: mainLoader 2.380952380952381s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.mainLoader div:nth-child(1) {
  border-color: #f57f20;
  animation-delay: 0s;
}
.mainLoader div:nth-child(2) {
  border-color: #f57f20;
  animation-delay: -1.1904761904761905s;
}
.mainLoaderContainer {
  width: 60px;
  height: 60px;
  display: inline-block;
  overflow: hidden;
}
.mainLoader {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.6);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.mainLoader div {
  box-sizing: content-box;
}

/* Scale Loader */
.scaleLoader {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 30px;
}
.scaleLoader div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 8px;
  background: #f57f20;
  animation: scaleLoader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.scaleLoader div:nth-child(1) {
  left: 5px;
  animation-delay: -0.36s;
}
.scaleLoader div:nth-child(2) {
  left: 20px;
  animation-delay: -0.24s;
}
.scaleLoader div:nth-child(3) {
  left: 35px;
  animation-delay: -0.12s;
}
.scaleLoader div:nth-child(4) {
  left: 50px;
  animation-delay: 0;
}

@keyframes scaleLoader {
  0% {
    top: -5px;
    height: 32px;
  }
  50%,
  100% {
    top: 10px;
    height: 16px;
  }
}

/* List Sceleton */
.listSkeletonBox {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}

.listLoading {
  margin: 7px 10px;
  width: 280px;
  height: 20px !important;
  border-radius: 10px !important;
}
