@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

.login-container {
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
}

.login-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #e0e0e0;
}

.login-logo {
  margin-top: 10px;
  margin-bottom: 10px;
  width: auto;
  height: 50px;
}

.login-vertical-divider {
  width: 300px;
  height: 2px;
  background-color: var(--disable-color);
}

.login-header-name {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 35px;
}

.login-body {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 30px;
}

.login-body-info {
  max-width: 750px;
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.login-body-info-header {
  margin-left: 75px;
}

.login-row-header {
  margin-bottom: 10px;
  font-size: 16px;
}

.login-body-info-row {
  display: flex;
  margin-top: 30px;
  align-items: flex-end;
}

.login-row-icon {
  height: 50px;
  width: auto;
  margin-right: 25px;
}

.login-horizontal-divider {
  height: 2px;
  margin-top: 50px;
  background-color: var(--disable-color);
  margin-left: 75px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.login-form-header {
  font-size: 25px;
  margin-bottom: 20px;
}

.login-form-request-header {
  margin-bottom: 10px;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 325px;
  height: 760px;
}

.login-form-container-block {
  position: absolute;
  background-color: var(--ground_color);
  top: 0;
  height: 50%;
  transition: all 0.6s ease-in-out;
}

.login-sign-in-container {
  top: 0;
  width: 100%;
  z-index: 2;
}

.login-form-container.right-panel-active .login-sign-in-container {
  transform: translateY(100%);
}

.login-sign-up-container {
  top: 0;
  width: 100%;
  opacity: 0;
  z-index: 1;
}

.login-form-container.right-panel-active .login-sign-up-container {
  transform: translateY(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

.login-overlay-container {
  position: absolute;
  /* top: 0; */
  top: 50%;
  width: 100%;
  height: 50%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.login-form-container.right-panel-active .login-overlay-container {
  transform: translateY(-100%);
}

.login-overlay {
  background: #ee7a00;
  background: -webkit-linear-gradient(to right, #ee7a00, #ff8504);
  background: linear-gradient(to right, #ee7a00, #ff8504);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  top: -100%;
  height: 200%;
  width: 100%;
  transform: translateY(0);
  transition: transform 0.6s ease-in-out;
}

.login-form-container.right-panel-active .login-overlay {
  transform: translateY(50%);
}

.login-overlay-header {
  color: var(--ground_color);
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 50px;
}

.login-request-header {
  margin-bottom: 110px;
}

.login-overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  /* top: 0; */
  height: 50%;
  width: 100%;
  transform: translateY(0);
  transition: transform 0.6s ease-in-out;
}

.login-overlay-left {
  transform: translateY(-20%);
}

.login-form-container.right-panel-active .login-overlay-left {
  transform: translateY(0);
}

.login-overlay-right {
  bottom: 0;
  transform: translateY(0);
}

.login-form-container.right-panel-active .login-overlay-right {
  transform: translateY(20%);
}

.login-button {
  border-radius: 20px;
  border: 1px solid #ee7a00;
  background-color: #ee7a00;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 35px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
  margin-top: 30px;
}

.login-button:active {
  transform: scale(0.95);
}

.login-button:focus {
  outline: none;
}

.login-button:disabled {
  background-color: var(--disable-color);
  border: 1px solid var(--disable-color);
  color: var(--ground_color);
  cursor: not-allowed;
}

.login-submit-btn {
  margin-top: 7px;
}

.login-ghost {
  background-color: transparent;
  border-color: #ffffff;
  margin-top: 55px;
}

.login-ghost-overlay {
  margin-top: 115px;
}

.login-form-main {
  height: 100%;
}

.login-form-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  height: 100%;
  text-align: center;
}

.login-forgot {
  margin-top: 15px;
  font-size: 13px;
}

.login-forgot:hover {
  color: var(--secondary-color);
}

.login-input {
  background-color: #eee;
  border: 1px solid #eee;
  padding: 10px 15px;
  font-size: 14px;
  margin: 5px 0;
  width: 100%;
  outline: none;
}

.login-input:focus {
  border: 1px solid var(--secondary-color);
}

.login-request-input {
  border: 1px solid var(--primary-color);
  padding: 5px 12px;
  margin-bottom: 2px;
  margin-top: 13px;
  width: 240px;
}

.login-request-input-error {
  border: 1px solid var(--error-color);
}

.login-request-input-error:focus {
  border: 1px solid var(--error-color);
}

.login-request-input:focus {
  outline: none;
  border: 1px solid var(--secondary-color);
}

.login-label-box {
  position: relative;
}

.login-label {
  position: absolute;
  display: grid;
  background-color: var(--ground_color);
  font-size: 12px;
  text-align: left;
}

.login-form-error {
  width: 230px;
  color: var(--error-color);
  font-size: 12px;
  height: 15px;
  text-align: left;
}

.login-label-error {
  color: var(--error-color);
  font-size: 12px;
  height: 15px;
  text-align: right;
}

.login-request-title {
  font-size: 13px;
  margin-bottom: 20px;
}

.allert-email-error {
  margin-top: 30px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: var(--error-color);
  border-radius: 10px;
  background-color: #fab5b5;
  font-weight: 600;
  width: 325px;
}

.allert-email-error-icon {
  font-size: 30px;
  width: 50px;
}

.login-success {
  position: absolute;
  padding: 15px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #ee7a00, #ff8504);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  z-index: 101;
}

.login-success-icon {
  height: 90px;
  width: auto;
  margin-bottom: 20px;
}

.login-success-text {
  color: var(--ground_color);
  text-align: center;
  max-width: 500px;
  font-size: 18px;
}

.login-success-text p {
  margin-bottom: 20px;
}

.login-success-text-header {
  font-size: 22px;
  font-weight: 600;
}

.login-success-link {
  font-weight: 600;
  color: var(--ground_color);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.login-success-link-icon {
  width: 30px !important;
  height: auto;
  margin-right: 10px;
}

.login-links {
  margin-top: 30px;
  margin-left: 75px;
  color: var(--disable-color);
}
.login-links-header {
  color: var(--disable-color);
}

.login-links-list {
  margin-top: 20px;
}

.login-links-list li {
  margin-top: 10px;
}

.login-links-list a {
  color: var(--disable-color);
}

.login-links-list a:hover {
  color: var(--primary-color);
}

/* Reset Password Page */
.resetpass-container {
  display: grid;
  align-content: center;
  justify-items: center;
  height: 80vh;
}

.resetpass-form {
  display: grid;
  justify-items: center;
  margin-top: 20px;
  padding: 50px;
  padding-bottom: 40px;
  box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
}

.resetpass-complete {
  display: grid;
  justify-items: center;
  align-items: center;
  margin-top: 20px;
  padding: 40px;
  padding-bottom: 40px;
  box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
  background: linear-gradient(to right, #ee7a00, #ff8504);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  width: 330px;
}

.resetpass-header {
  margin-bottom: 40px;
}

.resetpass-back {
  display: flex;
  align-items: center;
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  width: fit-content;
}

.resetpass-back:hover {
  color: var(--secondary-color);
}

.resetpass-back-icon {
  margin-right: 10px;
  font-size: 20px;
}

.login-success-redirect {
  margin-top: 25px;
  max-width: 300px;
  font-size: 13px;
  padding: 0 30px;
  text-align: center;
}

@media (min-width: 768px) and (min-height: 650px) {
  .login-header {
    margin-top: 20px;
  }
}

@media (min-width: 768px) and (min-height: 750px) {
  .login-header {
    margin-top: 90px;
  }
}

@media (min-width: 768px) {
  .login-header {
    height: 80px;
    flex-direction: row;
  }

  .login-vertical-divider {
    height: 60px;
    width: 2px;
  }

  .login-logo {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 80px;
    margin-right: 60px;
  }

  .login-header-name {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 70px;
  }

  .login-body {
    margin-top: 100px;
  }

  .login-form-container-block {
    top: 0;
    height: 100%;
  }

  .login-sign-in-container {
    left: 0;
    width: 50%;
  }

  .login-form-container.right-panel-active .login-sign-in-container {
    transform: translateX(100%);
  }

  .login-sign-up-container {
    left: 0;
    width: 50%;
  }

  .login-form-container.right-panel-active .login-sign-up-container {
    transform: translateX(100%);
  }

  .login-form-container {
    display: flex;
    flex-direction: row;
    width: 650px;
    height: 380px;
  }

  .login-overlay-container {
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
  }

  .login-form-container.right-panel-active .login-overlay-container {
    transform: translateX(-100%);
  }

  .login-overlay {
    top: 0;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
  }

  .login-form-container.right-panel-active .login-overlay {
    transform: translateX(50%);
  }

  .login-overlay-panel {
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
  }

  .login-overlay-left {
    transform: translateX(-20%);
  }

  .login-form-container.right-panel-active .login-overlay-left {
    transform: translateX(0);
  }

  .login-overlay-right {
    right: 0;
    top: 0;
    bottom: auto;
    transform: translateX(0);
  }

  .login-form-container.right-panel-active .login-overlay-right {
    transform: translateX(20%);
  }

  .allert-email-error {
    width: 650px;
  }
}

@media (min-width: 1024px) {
  .login-body {
    flex-direction: row;
  }

  .login-form {
    height: 500px;
    margin-top: 20px;
  }

  .login-body-info {
    margin-top: 0;
  }
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}
